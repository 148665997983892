/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { createReducer, on } from '@ngrx/store';
import { refreshScheduleFailure } from '@store/schedule/schedule.actions';
import {
  setDashboardLoading,
  setGlobalLoading,
  setLoginLoading,
  setProfileLoading,
} from './loading.actions';

export interface LoadingState {
  isLoading: boolean;
  msg: string;
}

export interface LoaderState {
  loginLoading: LoadingState;
  dashboardLoading: LoadingState;
  profileLoading: LoadingState;
  globalLoading: LoadingState;
}

export const initialState: LoaderState = {
  loginLoading: { isLoading: false, msg: '' },
  dashboardLoading: { isLoading: false, msg: '' },
  profileLoading: { isLoading: false, msg: '' },
  globalLoading: { isLoading: false, msg: '' },
};

export const loadingReducer = createReducer(
  initialState,
  on(setLoginLoading, (state, { isLoading, msg }) => ({
    ...state,
    loginLoading: { isLoading, msg: msg ?? '' },
  })),
  on(setDashboardLoading, (state, { isLoading, msg }) => ({
    ...state,
    dashboardLoading: { isLoading, msg: msg ?? '' },
  })),
  on(setProfileLoading, (state, { isLoading, msg }) => ({
    ...state,
    profileLoading: { isLoading, msg: msg ?? '' },
  })),
  on(setGlobalLoading, (state, { isLoading, msg }) => ({
    ...state,
    globalLoading: { isLoading, msg: msg || '' },
  })),
  // Set error and stop loading on refresh failure
  on(refreshScheduleFailure, (state) => ({
    ...state,
    globalLoading: { isLoading: false, msg: 'Failed to refresh schedule' },
    dashboardLoading: { isLoading: false, msg: '' },
  })),
);
